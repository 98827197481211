import React, { useEffect } from 'react';
import { graphql, navigate } from 'gatsby';
import Layout from 'layout';
import Container from 'elements/Container';
import { getFilePath } from '../utils/links';

const getDocLink = node => {
  const { frontmatter } = node;
  const { toc, path } = frontmatter;
  const tocSlug =
    (toc[0].items && toc[0].items[0] && toc[0].items[0].slug) || toc[0].slug;
  const tocLink = getFilePath('docs', `${path}-${tocSlug}`);
  return tocLink;
};

export default ({ data }) => {
  const { node } = data.allMarkdownRemark.edges[0];
  const link = getDocLink(node);
  useEffect(() => {
    navigate(link, { replace: true });
  }, []);
  return (
    <Layout title="Redirecting">
      <Container style={{ height: 'calc(100% - 500px)' }}>
        <h3>
          Redirecting to
          {link} 
        </h3>
      </Container>
    </Layout>
  );
};

export const query = graphql`
  query($dirNameRegex: String!) {
    allMarkdownRemark(
      filter: {
        fields: {
          collection: { eq: "docs" }
          dirName: { regex: $dirNameRegex }
        }
      }
    ) {
      edges {
        node {
          frontmatter {
            title
            category
            logo
            breadcrumb
            toc {
              slug
              title
              items {
                text
                slug
              }
            }
            path
          }
          fields {
            slug
            collection
            fileName
            dirName
          }
        }
      }
    }
  }
`;
